<template>
  <WeContainer card="" v-if="ready">
    <WeCard class="sticky-top mb-3">
      <div class="row align-items-center">
        <div class="col">
          <h5 class="m-0"><i class="far fa-copyright"></i> {{ getTitle }}</h5>
        </div>
        <div class="col-auto">
          <div class="row align-items-center">
            <div class="col-auto p-0">
              <WeLanguageSwitcher v-model="brand.lang" />
            </div>
            <div class="col pl-0">
              <!-- Submit -->
              <WeSubmitButton
                parent-class="text-center"
                v-bind:submit-status="submit"
                v-bind:update="$route.params.id"
                v-bind:permission="checkPermission"
                v-on:submit="submitForm"
              />
              <!-- ./Submit -->
            </div>
          </div>
        </div>
      </div>
    </WeCard>

    <WeCard>
      <!-- Marka Adı -->
      <div class="form-group mb-3">
        <WeInput
          label="Marka Adı"
          name="brand_name"
          v-bind:required="true"
          v-model="form.name"
          v-bind:error="$v.form.name.$error"
        />
      </div>
      <!-- ./Marka Adı -->

      <div class="row">
        <div class="col-12 col-lg-6">
          <!-- Slug -->
          <WeSlugCheck
            v-bind:value="form.name"
            v-bind:id="$route.params.id"
            v-bind:slug="form.slug"
            v-bind:search="true"
            v-on:slugify="changeSlug"
            :url-fragment="fragment"
            type="brand"
          />
          <!-- ./Slug -->
        </div>
        <div class="col-12 col-lg-6">
          <label class="custom-label">Tip Seç</label>
          <v-select
            v-model="form.types"
            label="name"
            v-bind:options="brandType"
            v-bind:reduce="(opt) => opt.id"
            v-bind:clearable="true"
            class="setting-select"
          >
          </v-select>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="custom-label">Durum</label>
            <WeSwitch v-model="form.is_active" label="Aktif" />
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="form-group">
            <label class="custom-label">Mikro Sitede Göster</label>
            <WeSwitch v-model="form.is_micro_site" label="Aktif" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <WeImageUploader
            v-model="form.image"
            v-bind:image-required="true"
            v-bind:fixed-meta-alt="form.name"
            v-bind:fixed-meta-title="form.name"
            title="Marka Logosu"
            ref="imageuploader"
          />
        </div>
        <div class="col-lg-6">
          <WeImageUploader
            v-model="form.mobile_image"
            v-bind:fixed-meta-alt="form.name"
            v-bind:fixed-meta-title="form.name"
            title="Marka Mobil Logosu"
            ref="imageuploader"
          />
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-lg-6">
          <div class="form-group mb-3">
            <WeInput label="Başlık" name="brand_title" v-model="form.title" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group mb-3">
            <WeInput
              label="Alt Başlık"
              name="brand_title"
              v-model="form.sub_title"
            />
          </div>
        </div>
      </div>
      <WeDescription
        v-model="form.description"
        v-bind:access-token="session.accessToken"
        v-bind:name="'brand'"
        v-bind:title="$t('description')"
      />

      <div class="form-group mb-3">
        <WeInput
          label="Embed Url"
          name="brand_title"
          placeholder="https://"
          v-model="form.embed_url"
        />
      </div>

      <WeMetaData v-model="form.meta" />

      <WeSeoBar
        v-bind:focus-keyword="form.name"
        v-bind:metaTitle="form.meta.title"
        v-bind:metaDescription="form.meta.description"
        v-bind:description="form.description.text"
        v-bind:images="form.image.files"
        v-bind:slug="form.slug"
      />
    </WeCard>
  </WeContainer>
  <WeLoading v-else />
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Detail",
  data() {
    return {
      ready: false,
      submit: false,
      brandType: [],
      fragment: "marka",
      form: {
        name: null,
        slug: null,
        is_active: true,
        is_micro_site: false,
        title: null,
        sub_title: null,
        embed_url: null,
        types: null,
        description: {
          text: null,
          show: false,
          place: "before",
        },
        meta: {
          title: null,
          keywords: null,
          description: null,
          robots: ["index", "follow"],
          canonicalUrl: null,
        },
        image: {
          files: [],
          filesData: null,
        },
        mobile_image: {
          files: [],
          filesData: null,
        },
      },
      cleanData: {},
      cleanRequest: {},
      tmpLang: null,
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("brand", ["create", "update", "getById"]),
    ...mapActions("brandType", ["getList"]),
    ...mapMutations("shared", ["setMessage", "clearMessage"]),
    validateForm() {
      this.$v.$touch();
      /* let imageValid = this.$refs.imageuploader.$refs.single.$v;
      imageValid.$touch();*/

      if (this.$v.$invalid /*|| imageValid.$invalid*/) {
        this.$swal({
          title: "Hata",
          text: "Lütfen zorunlu alanları doldurun",
          icon: "error",
        });

        return false;
      } else {
        return true;
      }
    },
    getFragment(types) {
      if (types.length <= 0) {
        return "marka";
      } else {
        return types[0].slug;
      }
    },
    submitForm() {
      if (!this.validateForm()) {
        return;
      }
      this.submit = true;

      if (this.$route.params.id) {
        if (this.tmpLang == this.brand.lang) {
          this.cleanRequest = helper.difference(this.form, this.cleanData, [
            "image",
            "mobile_image"
          ]);
        } else this.cleanRequest = this.form;

        this.updateBrand();
      } else {
        this.addBrand();
      }
    },
    showConfirmation(cancelButtonText, id) {
      this.$swal({
        title: "İşlem Başarılı",
        text: "Markalar listesine dönebilir veya bu sayfada kalabilirsiniz.",
        icon: "success",
        showCancelButton: true,
        cancelButtonText: cancelButtonText,
        confirmButtonText: "Liste Sayfasına Dön",
      }).then((confirm) => {
        if (confirm.isConfirmed) {
          this.redirectToList();
        } else {
          if (id) {
            this.$router.push(`detail/${id}`);
          } else {
            window.location.reload();
          }
        }
      });
    },
    addBrand() {
      this.create({
        form: this.form,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Detaya Git", result.data.id);
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    updateBrand() {
      this.update({
        id: this.$route.params.id,
        form: this.cleanRequest,
        onSuccess: (result) => {
          if (result && result.data && result.data.status !== "error") {
            this.showConfirmation("Sayfada Kal");
          } else {
            this.showAlert(result);
          }
        },
        onFinish: () => {
          this.submit = false;
        },
      });
    },
    getBrandById() {
      if (this.$route.params.id) {
        this.getById({
          id: this.$route.params.id,
          onSuccess: (result) => {
            this.form = result;
            this.cleanData = helper.clone(this.form);
            this.ready = true;
            this.fragment = this.getFragment(result.types);
          },
        });
      } else {
        this.ready = true;
      }
    },
    getBrandTypes() {
      this.getList({
        lang: this.tmpLang,
        onSuccess: (result) => {
          this.brandType = result.data.items;
        },
      });
    },
    setChanged() {
      sessionStorage.setItem("changed", true);
    },
    removeChanged() {
      sessionStorage.removeItem("changed");
    },
    redirectToList() {
      this.$router.push("/brands");
    },
    showAlert(result, callback) {
      this.setMessage(result);
      this.$swal(this.shared.message).then((confirm) => {
        if (callback && confirm.isConfirmed) callback();
      });
      this.clearMessage();
    },
    changeSlug(data) {
      this.form.slug = data;
    },
  },
  computed: {
    ...mapState(["brand", "shared", "session"]),
    getTitle() {
      return this.$route.params.id ? "MARKA DÜZENLE" : "MARKA EKLE";
    },
    submitButtonText() {
      return this.$route.params.id ? "Güncelle" : "Kaydet";
    },
    checkPermission() {
      return permission.check("brand", "u");
    },
  },
  mounted() {
    this.getBrandTypes();
    this.getBrandById();
    this.tmpLang = this.brand.lang;
  },
  watch: {
    "brand.lang": {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.ready = false;
          this.getBrandById();
        }
      },
    },
    "form.types": {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.fragment = this.getFragment(
            this.brandType.filter((A) => A.id == newVal)
          );
        }
      },
    },
  },
};
</script>
